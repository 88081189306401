<!--  -->
<template>
  <div class="home">
    <el-upload
      class="video-uploader"
      :action="qiniuForm.uploadUrl"
      :accept="'video/*'"
      :data="{ token: qiniuForm.videoToken }"
      :show-file-list="false"
      :on-success="uploadVideoSuccess"
      :on-error="uploadVideoError"
      :before-upload="beforeVideoUpload"
    >
    </el-upload>

    <el-upload
      class="avatar-uploader"
      :action="qiniuForm.uploadUrl"
      :accept="'image/*'"
      :data="{ token: qiniuForm.imgToken }"
      :show-file-list="false"
      :on-success="uploadEditorSuccess"
      :on-error="uploadEditorError"
      :before-upload="beforeEditorUpload"
    >
    </el-upload>

    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="标题" label-width="80px" prop="title">
        <el-input v-model="form.title" placeholder="请输入内容"></el-input>
      </el-form-item>
    </el-form>
    <div id="quill_box"></div>
    <el-button
      type="warning"
      round
      class="btn"
      @click="submit"
      :loading="loading"
      >修改公告</el-button
    >
  </div>
</template>

<script>
import { editNotice, noticeDetail } from "@/api/notice";
import Quill from "quill";
import { getImgToken, getVideoToken } from "@/api/app";
import { setToken } from "@/utils/auth";
import { Message } from "element-ui";
let toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ["image"],
  ["video"],
];
export default {
  data() {
    return {
      id: 0,
      content: "",
      loading: false,
      quillUpdateImg: "",
      form: {
        title: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
      },
      qiniuForm: {
        imgToken: "",
        videoToken: "",
        uploadUrl: "http://up.qiniup.com",
        domain: "http://temporary.hengruidichan.com/", //临时域名
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var params = {
            Contents: this.setImg(this.content),
            Title: this.form.title,
            Id: Number(this.id),
          };
          this.loading = true;
          editNotice(params)
            .then((resp) => {
              console.log(resp);
              Message({
                message: "修改成功!",
                type: "success",
              });
              this.loading = false;
            })
            .catch((e) => {
              console.log(e);
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    setImg(json) {
      console.log("=======json=====>", JSON.stringify(json));
      // for(var i=0;i<json.length;i++){
      //      if(json[i].insert && json[i].insert.image){
      //         let str = json[i].insert.image;
      //          if(str.indexOf("http://file.hengruidichan.com/img/") != -1){
      //           str = str.replace("http://file.hengruidichan.com/img/","");
      //          }else if(str.indexOf("http://file.haipuwang.net/img/") != -1){
      //           str = str.replace("http://file.haipuwang.net/img/","");
      //          }
      //       json[i].insert.image = str
      //    }
      //  }
      return json;
    },
    getDetail() {
      noticeDetail(this.id).then((resp) => {
        this.form.title = resp.Title;
        let json = JSON.parse(resp.Contents);
        for (var i = 0; i < json.length; i++) {
          if (json[i].insert && json[i].insert.image) {
            json[
              i
            ].insert.image = `${process.env.VUE_APP_CDNURL}${json[i].insert.image}`;
            console.log(json[i].image);
          }
        }
        this.quill.setContents(json);
      });
    },
    //上传图片之前
    beforeEditorUpload() {
      //显示上传动画
      this.quillUpdateImg = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },

    // 上传图片成功
    uploadEditorSuccess(res) {
      //拼接出上传的图片在服务器的完整地址
      let imgUrl = this.qiniuForm.domain + res.key;

      //重置上传文件key，为下次上传做好准备
      this.qiniuForm.key =
        new Date().getTime() + "" + Math.floor(Math.random() * 1000);

      // 获取富文本组件实例
      let quill = this.quill;

      // 获取光标所在位置
      let length = quill.getSelection().index;
      console.log("image----", imgUrl);
      // 插入图片  res.info为服务器返回的图片地址
      // quill.insertEmbed(length, '\n')
      quill.insertEmbed(length, "image", imgUrl);

      // 添加样式
      quill.formatText(length, 1, { width: "100%" });

      // quill.insertEmbed(length,'br')
      // 调整光标到最后
      quill.setSelection(length + 1);

      //取消上传动画
      // this.quillUpdateImg = false;
      this.quillUpdateImg.close();
    },

    // 上传图片失败
    uploadEditorError() {
      //页面提示
      this.$message.error("上传图片失败");

      //取消上传动画
      this.quillUpdateImg.close();
    },
    uploadVideoSuccess(res) {
      let videoUrl = this.qiniuForm.domain + res.key;
      this.qiniuForm.key =
        new Date().getTime() + "" + Math.floor(Math.random() * 1000);
      let quill = this.quill;
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "video", videoUrl);
      quill.formatText(length, 1, { width: "100%", controls: "controls" });
      quill.setSelection(length + 1);
      this.quillUpdateImg.close();
    },
    uploadVideoError() {
      //页面提示
      this.$message.error("上传视频失败");

      //取消上传动画
      this.quillUpdateImg.close();
    },
    beforeVideoUpload(res) {
      console.log("上传视频之前----", res);
      //显示上传动画
      this.quillUpdateImg = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
  },
  mounted() {
    this.id = this.getQueryVariable("Id");
    let token = this.getQueryVariable("Token");
    setToken(token);

    this.quill = new Quill("#quill_box", {
      theme: "snow",
      modules: {
        toolbar: {
          container: toolbarOptions, // 工具栏
          handlers: {
            image: function (value) {
              if (value) {
                document.querySelector(".home .avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
            video: function (value) {
              if (value) {
                document.querySelector(".home .video-uploader input").click();
              } else {
                this.quill.format("video", false);
              }
            },
          },
        },
      },
    });
    this.getDetail();
    this.quill.on("text-change", (delta, oldDelta, source) => {
      console.log(delta, oldDelta, source);
      this.content = this.quill.getContents().ops;
    });
    getImgToken().then((resp) => {
      this.qiniuForm.imgToken = resp.Token;
    });
    getVideoToken().then((resp) => {
      this.qiniuForm.videoToken = resp.Token;
    });
  },
};
</script>
<style lang='scss' >
.home {
  // width: 100%;
  height: 100%;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 15px;
}
.btn {
  margin: 20px 0;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
  object-fit:fill;
	width:400px;
	height:320px;
  margin: 10px 0;
}
</style>